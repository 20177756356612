import M from 'materialize-css';
import axiosInstance from './axiosSetup';

const dataLoader = (url, settings, setLoading) => {

    const controller = new AbortController();
    
    axiosInstance.get(url, {
        signal: controller.signal
    }).then(function (res) {
        
        if (res.data.code === 200) {
            for (const [setting, setSetting] of Object.entries(settings)) {
                setSetting(res.data.data[setting]);
            }
        }
        else if (res.data.code === 403) {
            M.toast({html: "Vous n'avez pas les autorisations nécessaires", classes: 'red'});
        }
        else {
            M.toast({html: 'Une erreur est survenue lors du chargement', classes: 'red'});
        }

        if (setLoading !== null) {
            setLoading(false);
        }
        
    }).catch(function (error) {
        console.log(error);
        if (error.message !== "canceled") {
            M.toast({html: 'Serveur indisponible', classes: 'red'});
            if (setLoading !== null) {
                setLoading(false);
            }
        }
    });

    return controller;
};

export default dataLoader;