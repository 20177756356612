import { type MomentLocale } from "../../types";

const momentLocalesDe: MomentLocale = {
    months: [
        "Januar",
        "Februar",
        "März",
        "April",
        "Mai",
        "Juni",
        "Juli",
        "August",
        "September",
        "Oktober",
        "November",
        "Dezember"
    ],
    monthsShort : [
        "jan.", 
        "feb.", 
        "mär.", 
        "avr.", 
        "mai.", 
        "jun.", 
        "jul.", 
        "aug.", 
        "sep.", 
        "okt.", 
        "nov.", 
        "dez."
    ],
    weekdays : [
        "Sonntag", 
        "Montag", 
        "Dienstag", 
        "Mittwoch", 
        "Donnerstag", 
        "Freitag", 
        "Samstag"
    ],
    weekdaysShort : [
        "son.", 
        "mon.", 
        "die.", 
        "mit.", 
        "don.", 
        "fre.", 
        "sam."
    ],
    weekdaysMin : [
        "So", 
        "Mo", 
        "Di", 
        "Mi", 
        "Do", 
        "Fr", 
        "Sa"
    ],
    weekdaysAbbrev : [
        "So", 
        "Mo", 
        "Di", 
        "Mi", 
        "Do", 
        "Fr", 
        "Sa"
    ]
}

export default momentLocalesDe;