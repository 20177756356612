import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useIdentity } from "../contexts/IdentityContext";
import Layout from "./Layout";
import Loading from "./Loading";


const ProtectedRoute = (props: { children: JSX.Element}) => {
    const { isLogged, user } = useIdentity();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isLogged || !user) {
            navigate('/login');
        }
    }, [navigate, isLogged, user]);

    return (isLogged && user) ? <Layout>
        {props.children}
    </Layout> : <Loading />;
};

export default ProtectedRoute;