import i18n from 'i18next';
import ChainedBackend, { type ChainedBackendOptions } from "i18next-chained-backend";
import HttpApi from 'i18next-http-backend';
import LocalStorageBackend from "i18next-localstorage-backend";
import { initReactI18next } from 'react-i18next';

i18n.use(ChainedBackend).use(initReactI18next).init<ChainedBackendOptions>({
    backend: {
        backends: [
            LocalStorageBackend,
            HttpApi
        ],
        backendOptions: [
            {
                prefix: 'mecadrive_locales_',
                expirationTime: 14 * 24 * 60 * 60 * 1000,
                defaultVersion: process.env.REACT_APP_VERSION
            },
            {
                loadPath: process.env.REACT_APP_API_URL+'/locales/{{lng}}/{{ns}}',
                addPath: process.env.REACT_APP_API_URL+'/translations/{{lng}}/{{ns}}',
                //crossDomain: true,
			
                /*customHeaders: {
                    "Company-Id": process.env.REACT_APP_COMPANY_ID
                }*/
            }
        ],
        cacheHitMode: 'refreshAndUpdateStore',
        reloadInterval: 4 * 60 * 60 * 1000
    },
    //load: 'languageOnly',
    ns: ['translation'],
    defaultNS: 'translation',
    lng: 'fr',
    fallbackLng: 'fr',
    supportedLngs: ['fr', 'en', 'de'],
    interpolation: {
        escapeValue: false
    },
    react: {
        useSuspense: true
    },
    debug: false,
    saveMissing: process.env.NODE_ENV === 'development' ? false : false
});

export default i18n;