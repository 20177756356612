import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from '@azure/msal-react';
import { createContext, useContext, useEffect, useState } from 'react';
import Loading from '../components/Loading';
import signature from '../locales/signature';
import { type Configuration } from '../types';
import axiosInstance from '../utils/axiosSetup';

interface Props {
    hasNetwork: boolean;
    CONF: Configuration;
    confLoading: boolean;
    geolocationGranted: boolean;
    queryGeolocationPermission: Function;
}

const AppContext = createContext<Props | null>(null);

const useApp = () => {
    const currentAppContext = useContext(AppContext);
    if (!currentAppContext) {
        throw new Error(
            "useApp has to be used within <AppContext.Provider>"
        );
    }
    
    return currentAppContext;
};

function AppProvider(props: {children: JSX.Element}) {
    
    const [hasNetwork, setHasNetwork] = useState<boolean>(navigator.onLine);
    const [loading, setLoading] = useState<boolean>(true);
    const [CONF, setCONF] = useState<Configuration | null>(null);
    const [geolocationGranted, setGeolocationGranted] = useState<boolean>(false);
    const [msalInstance, setMsalInstance] = useState<PublicClientApplication | null>(null);
    const [msalLoading, setMsalLoading] = useState<boolean>(false);

    const saveConf = (CONF: Configuration) => {
        localStorage.setItem("mecadrive_configuration", JSON.stringify(CONF));
        setCONF(CONF);
    };

    const getLocalConf = () => {
        const localConf = localStorage.getItem("mecadrive_configuration");
        if (localConf) {
            setCONF(JSON.parse(localConf) as Configuration);
        }
        else {
            // No configuration
        }
    };

    const refreshConfiguration = () => {

        const controller = new AbortController();
        axiosInstance.get('/configuration', { signal: controller.signal }).then(function (res) {
            if (res.data.code === 200) {
                saveConf(res.data.data['configuration'] as Configuration);
            }
            else {
                getLocalConf();
            }

            setLoading(false);
            
        }).catch(function (error) {
            if (error.code !== "ERR_CANCELED") {
                console.log(error);
                getLocalConf();
                setLoading(false);
            }
            
        });

        return controller;
    };

    const queryGeolocationPermission = () => {
        if ("permissions" in navigator) {
            navigator.permissions.query({ name: "geolocation" }).then((result) => {
                if (["granted", "denied"].includes(result.state)) {
                    setGeolocationGranted(result.state === "granted" ? true : false);
                }
                else {
                    navigator.geolocation.getCurrentPosition(
                        () => setGeolocationGranted(true), 
                        () => setGeolocationGranted(false), 
                        {
                            timeout: 4000,
                            maximumAge: 0
                        }
                    );
                }
    
                result.onchange = () => {
                    setGeolocationGranted(result.state === "granted" ? true : false);
                };
            });
        }
        else if ("geolocation" in navigator) {
            (navigator as Navigator).geolocation.getCurrentPosition(
                () => setGeolocationGranted(true), 
                () => setGeolocationGranted(false), 
                {
                    timeout: 4000,
                    maximumAge: 0
                }
            );
        }
    };
    
    useEffect(() => {
        console.log('%c'+signature, 'color: #1565C0;');
        window.addEventListener('online', () => setHasNetwork(true));
        window.addEventListener('offline', () => setHasNetwork(false));

        return () => {
            window.removeEventListener("online", () => setHasNetwork(true));
            window.removeEventListener("offline", () => setHasNetwork(false));
        };
    }, []);

    useEffect(() => {
        if (!geolocationGranted) {
            queryGeolocationPermission();
        }
    }, [geolocationGranted]);

    useEffect(() => {
        let httpReq: AbortController | null = null;
        if (CONF === null) {
            httpReq = refreshConfiguration();
        }
        
        return () => {
            if (httpReq) {
                httpReq.abort();
            }
        };

    }, [hasNetwork]);

    useEffect(() => {
        if (CONF && CONF.MSAL && CONF.MSAL_CONFIGURATION) {
            setMsalLoading(true);
            setMsalInstance(new PublicClientApplication(CONF.MSAL_CONFIGURATION));
        }
    }, [CONF]);

    useEffect(() => {
        if (msalInstance) {
            msalInstance.initialize().then(() => {
                setMsalLoading(false);
            });
        }
    }, [msalInstance]);

    return (
        <AppContext.Provider value={CONF ? { 
            hasNetwork: hasNetwork,
            CONF: CONF, 
            confLoading: loading,
            geolocationGranted: geolocationGranted,
            queryGeolocationPermission
        } as Props : null} 
        {...props}>

            {(loading || CONF === null) ? <Loading /> : <>
                {CONF.MSAL ? 
                    ((msalInstance && !msalLoading) ? <MsalProvider instance={msalInstance}>{props.children}</MsalProvider> : <Loading />) : 
                    props.children
                }
            </>}

        </AppContext.Provider>
    );
}

export { AppProvider, useApp };

