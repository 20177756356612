
import moment from 'moment';
import NoSleep from 'nosleep.js';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import momentLocalesDe from '../locales/de/moment';
import momentLocalesFr from '../locales/fr/moment';
import { type LangISO, type Settings } from '../types';
import downloadOffline from '../utils/downloadOffline';
import { useIdentity } from './IdentityContext';
import { useLocalData } from './LocalDataContext';

const SettingsContext = createContext<{settings: Settings, setSettings: React.Dispatch<Settings>} | null>(null);

const useSettings = () => {
    const currentSettingsContext = useContext(SettingsContext);
    if (!currentSettingsContext) {
        throw new Error(
            "useSettings has to be used within <SettingsContext.Provider>"
        );
    }
    
    return currentSettingsContext;
};

function SettingsProvider(props: {children: JSX.Element}) {
    const { i18n } = useTranslation();

    const { setLocalDbInfo, setDownloadingOfflineData, upToDate } = useLocalData();
    const { role } = useIdentity();

    const localSettings = localStorage.getItem('mecadrive_settings');
    const [settings, setSettings] = useState<Settings>(localSettings ? JSON.parse(localSettings) : {
        sounds: false,
        night_mode: false,
        lang: navigator.language as LangISO,
        no_sleep: false,
        fixed_routeboard_header: false,
        route_map: false,
        route_map_size: [250, 250],
        seconds: false,
        all_brake_categories: false,
        geolocation_step_change: false,
        font_size: 1,
        automatic_data_update: false,
        local_mode: false,
        automatic_train_start: false,
        automatic_train_change: false,
        automatic_train_change_delay: 10,
        automatic_logout: false,
        automatic_logout_delay: 10,
        next_stop: true,
        next_step: true,
        late: true,
        advance: true,
        arrival_countdown: true,
        speed: true
    });

    const [noSleep] = useState<NoSleep>(new NoSleep());

    const saveSettings = (settings: Settings) => {
        localStorage.setItem("mecadrive_settings", JSON.stringify(settings));
    };

    useEffect(() => {
        saveSettings(settings);
    }, [settings]);

    useEffect(() => {
        if (role) {
            setSettings((prev) => ({
                sounds: role.settings.sounds.locked === true ? (role.settings.sounds.value === "1" ? true : false) : ('sounds' in prev ? prev.sounds : (role.settings.sounds.value === "1" ? true : false)),
                night_mode: role.settings.night_mode.locked === true ? (role.settings.night_mode.value === "1" ? true : false) : ('night_mode' in prev ? prev.night_mode : (role.settings.night_mode.value === "1" ? true : false)),
                lang: role.settings.lang.locked === true ? (role.settings.lang.value.length ? role.settings.lang.value : navigator.language) as LangISO : ('lang' in prev ? prev.lang : (role.settings.lang.value.length ? role.settings.lang.value : navigator.language) as LangISO),
                no_sleep: role.settings.no_sleep.locked === true ? (role.settings.no_sleep.value === "1" ? true : false) : ('no_sleep' in prev ? prev.no_sleep : (role.settings.no_sleep.value === "1" ? true : false)),
                fixed_routeboard_header: role.settings.fixed_routeboard_header.locked === true ? (role.settings.fixed_routeboard_header.value === "1" ? true : false) : ('fixed_routeboard_header' in prev ? prev.fixed_routeboard_header : (role.settings.fixed_routeboard_header.value === "1" ? true : false)),
                route_map: role.settings.route_map.locked === true ? (role.settings.route_map.value === "1" ? true : false) : ('route_map' in prev ? prev.route_map : (role.settings.route_map.value === "1" ? true : false)),
                route_map_size: ('route_map_size' in prev ? prev.route_map_size : [250, 250]),
                seconds: role.settings.seconds.locked === true ? (role.settings.seconds.value === "1" ? true : false) : ('seconds' in prev ? prev.seconds : (role.settings.seconds.value === "1" ? true : false)),
                all_brake_categories: role.settings.all_brake_categories.locked === true ? (role.settings.all_brake_categories.value === "1" ? true : false) : ('all_brake_categories' in prev ? prev.all_brake_categories : (role.settings.all_brake_categories.value === "1" ? true : false)),
                geolocation_step_change: role.settings.geolocation_step_change.locked === true ? (role.settings.geolocation_step_change.value === "1" ? true : false) : ('geolocation_step_change' in prev ? prev.geolocation_step_change : (role.settings.geolocation_step_change.value === "1" ? true : false)),
                font_size: role.settings.font_size.locked === true ? parseFloat(role.settings.font_size.value) : ('font_size' in prev ? prev.font_size : parseFloat(role.settings.font_size.value)),
                automatic_data_update: role.settings.automatic_data_update.locked === true ? (role.settings.automatic_data_update.value === "1" ? true : false) : ('automatic_data_update' in prev ? prev.automatic_data_update : (role.settings.automatic_data_update.value === "1" ? true : false)),
                local_mode: role.settings.local_mode.locked === true ? (role.settings.local_mode.value === "1" ? true : false) : ('local_mode' in prev ? prev.local_mode : (role.settings.local_mode.value === "1" ? true : false)),
                automatic_train_start: role.settings.automatic_train_start.locked === true ? (role.settings.automatic_train_start.value === "1" ? true : false) : ('automatic_train_start' in prev ? prev.automatic_train_start : (role.settings.automatic_train_start.value === "1" ? true : false)),
                automatic_train_change: role.settings.automatic_train_change.locked === true ? (role.settings.automatic_train_change.value === "1" ? true : false) : ('automatic_train_change' in prev ? prev.automatic_train_change : (role.settings.automatic_train_change.value === "1" ? true : false)),
                automatic_train_change_delay: role.settings.automatic_train_change_delay.locked === true ? parseInt(role.settings.automatic_train_change_delay.value) : ('automatic_train_change_delay' in prev ? prev.automatic_train_change_delay : parseInt(role.settings.automatic_train_change_delay.value)),
                automatic_logout: role.settings.automatic_logout.locked === true ? (role.settings.automatic_logout.value === "1" ? true : false) : ('automatic_logout' in prev ? prev.automatic_logout : (role.settings.automatic_logout.value === "1" ? true : false)),
                automatic_logout_delay: role.settings.automatic_logout_delay.locked === true ? parseInt(role.settings.automatic_logout_delay.value) : ('automatic_logout_delay' in prev ? prev.automatic_logout_delay : parseInt(role.settings.automatic_logout_delay.value)),
                next_stop: role.settings.next_stop?.locked === true ? (role.settings.next_stop.value === "1" ? true : false) : ('next_stop' in prev ? prev.next_stop : (role.settings.next_stop?.value === "1" ? true : false)),
                next_step: role.settings.next_step?.locked === true ? (role.settings.next_step.value === "1" ? true : false) : ('next_step' in prev ? prev.next_step : (role.settings.next_step?.value === "1" ? true : false)),
                late: role.settings.late?.locked === true ? (role.settings.late.value === "1" ? true : false) : ('late' in prev ? prev.late : (role.settings.late?.value === "1" ? true : false)),
                advance: role.settings.advance?.locked === true ? (role.settings.advance.value === "1" ? true : false) : ('advance' in prev ? prev.advance : (role.settings.advance?.value === "1" ? true : false)),
                arrival_countdown: role.settings.arrival_countdown?.locked === true ? (role.settings.arrival_countdown.value === "1" ? true : false) : ('arrival_countdown' in prev ? prev.arrival_countdown : (role.settings.arrival_countdown?.value === "1" ? true : false)),
                speed: role.settings.speed?.locked === true ? (role.settings.speed.value === "1" ? true : false) : ('speed' in prev ? prev.speed : (role.settings.speed?.value === "1" ? true : false))
            }));
        }
    }, [role]);

    useEffect(() => {
        document.documentElement.dataset.theme = (settings?.night_mode ? 'dark' : 'light');
    }, [settings?.night_mode]);

    useEffect(() => {
        if (settings?.lang) {
            i18n.changeLanguage(settings.lang);

            if (settings.lang === 'de') {
                moment.updateLocale(settings.lang, momentLocalesDe);
            }
            else {
                moment.updateLocale(settings.lang, momentLocalesFr);
            }
        }
    }, [i18n, settings?.lang]);

    useEffect(() => {
        if (settings !== null) {
            if (settings.no_sleep && !noSleep.isEnabled && !document.hidden) {
                noSleep.enable();
            }
            else if (!settings.no_sleep && noSleep.isEnabled) {
                noSleep.disable();
            }
        }
        
    }, [noSleep, settings?.no_sleep]);

    useEffect(() => {
        if (settings?.automatic_data_update && !upToDate) {
            downloadOffline(setDownloadingOfflineData, setLocalDbInfo);
        }
    }, [upToDate, settings?.automatic_data_update]);

    return (
        <SettingsContext.Provider value={{settings, setSettings}} {...props}>
            {props.children}
        </SettingsContext.Provider>
    );
}

export { SettingsProvider, useSettings };

