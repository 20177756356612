import { createContext, useContext, useEffect, useState } from 'react';
import { io, type Socket } from "socket.io-client";
import { useApp } from "./AppContext";
import { useIdentity } from "./IdentityContext";

interface Props {
    socketIO: Socket | null;
}

const SocketContext = createContext<Props>({socketIO: null});

const useSocket = () => {
    const currentSocketContext = useContext(SocketContext);
    if (!currentSocketContext) {
        throw new Error(
            "useSocket has to be used within <SocketContext.Provider>"
        );
    }
    
    return currentSocketContext;
};

function SocketProvider(props: {children: JSX.Element}) {
    
    const { token } = useIdentity();
    const { hasNetwork, CONF } = useApp();

    const [socketIO, setSocketIO] = useState<Socket | null>(null);

    useEffect(() => {
        if (token && hasNetwork && CONF.LIVE_API_URL) {
            if (!socketIO) {

                const socketConnection = io((CONF.LIVE_API_URL), {
                    //reconnectionAttempts: 10,
                    reconnectionDelayMax: 10000,
                    transports: ["websocket", "polling"],
                    rejectUnauthorized: false,
                    withCredentials: true,
                    auth: {
                        token: token
                    }
                });
				
                socketConnection.on('connect', () => {
                    setSocketIO(socketConnection);
                });

                socketConnection.on('disconnect', () => {
                    setSocketIO(socketIO);
                });

            }
        }
        else {
            if (socketIO) {
                socketIO.disconnect();
                setSocketIO(null);
            }
        }

        return () => {
            if (socketIO) {
                socketIO.disconnect();
                setSocketIO(null);
            }
        };
    }, [socketIO, token, hasNetwork, CONF.LIVE_API_URL]);

    return (
        <SocketContext.Provider value={{socketIO: socketIO}} {...props}>
            {props.children}
        </SocketContext.Provider>
    );
}

export { SocketProvider, useSocket };

