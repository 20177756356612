import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true
});

instance.interceptors.request.use((config) => {
    const token = sessionStorage.getItem('mecadrive_token') || localStorage.getItem('mecadrive_token');
    if (token !== null) {
        config.headers.Authorization =  token;
    }

    return config;
});

export default instance;