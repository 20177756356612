import {
    faTimes
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import M from 'materialize-css';
import moment from 'moment';
import { useEffect, type RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { useMessages } from '../../contexts/MessagesContext';
import Loading from '../Loading';

const SideMessages = (props: {sidemessagesRef: RefObject<HTMLDivElement>}) => {
    const { t } = useTranslation();

    const { messages, setCurrentMessage, messagesLoading } = useMessages();
    
    const { sidemessagesRef } = props;

    useEffect(() => {
        if (sidemessagesRef.current) {
            M.Sidenav.init(sidemessagesRef.current);
        }
    }, []);

    const closeSidenav = () => {
        const sideMessagesInst = M.Sidenav.getInstance(sidemessagesRef.current as HTMLDivElement);
        if (sideMessagesInst.isOpen) {
            sideMessagesInst.close();
        }
    };

    return (<div className="sidenav sidemessages" ref={sidemessagesRef}>
        <nav style={{marginBottom: '20px'}}>
            <div className="row">
                <div className="col s12" style={{display: 'flex', alignItems: 'center'}}>
                    <h6 style={{margin: 0, flex: '1 1 auto'}}>{t('messages')}</h6>
                    <a href="#" className="right" onClick={closeSidenav}>
                        <FontAwesomeIcon icon={faTimes}/>
                    </a>
                </div>
            </div>
        </nav>

        <div className="collection">
            
            {(messagesLoading) ? <Loading /> : (
                messages.length ? messages.map(message => (
                    <div key={message.id} className="collection-item" onClick={() => setCurrentMessage(message)}>
                        <p className="date">{moment(message.created_at).format('DD.MM.Y HH:mm')} 
                            {!message.is_read ? <span className="badge new">{t('new')}</span> : null}
                        </p>
                        <p className="user">{message.sender?.firstname} {message.sender?.lastname}</p>
                        <p className="message">{message.body}</p>
                    </div>
                )) : <div className="collection-item">{t('no-message')}</div>
            )}
            
        </div>
    
    </div>);
};

export default SideMessages;