import { AuthenticationResult } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { createContext, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { type Role, type User } from '../types';
import axiosInstance from '../utils/axiosSetup';
import { getLocalRole, getLocalUser } from '../utils/localDb';
import parseJwt from '../utils/parseJWT';
import { useApp } from './AppContext';
import { useDevice } from './DeviceContext';
import { useLocalData } from './LocalDataContext';

interface Props {
    user: User | null;
    role: Role | null;
    isLogged: boolean;
    token: string | null;
    auth: Function,
    msalAuth: Function,
    identify: Function,
    logout: Function,
    authLoading: boolean;
    logoutLoading: boolean;
    refreshLoading: boolean;
    deviceLimitExeeded: boolean;
    isAuthorized: Function;
}

const IdentityContext = createContext<Props>({
    user: null, 
    role: null, 
    isLogged: false,
    token: null,
    auth: () => {},
    msalAuth: () => {},
    identify: () => {},
    logout: () => {},
    authLoading: false,
    logoutLoading: false,
    refreshLoading: false,
    deviceLimitExeeded: false,
    isAuthorized: () => false
});

const useIdentity = () => {
    const currentIdentityContext = useContext(IdentityContext);
    if (!currentIdentityContext) {
        throw new Error(
            "useIdentity has to be used within <IdentityContext.Provider>"
        );
    }
    
    return currentIdentityContext;
};

function IdentityProvider(props: {children: JSX.Element}) {
    
    const { t } = useTranslation();

    const { CONF, hasNetwork } = useApp();
    const { localDbInfo } = useLocalData();
    const { deviceToken } = useDevice();

    const [authLoading, setAuthLoading] = useState<boolean>(false);
    const [logoutLoading, setLogoutLoading] = useState<boolean>(false);
    const [refreshLoading, setRefreshLoading] = useState<boolean>(true);
    const [user, setUser] = useState<User | null>(null);
    const [role, setRole] = useState<Role | null>(null);
    const [isLogged, setIsLogged] = useState<boolean>(false);
    const [token, setToken] = useState<string | null>(sessionStorage.getItem('mecadrive_token') || localStorage.getItem('mecadrive_token'));
    const [deviceLimitExeeded, setDeviceLimitExeeded] = useState<boolean>(false);
    const { instance: msalInstance, accounts: msalAccounts } = useMsal(); // Peut etre à passer comme paramètres pour la page login
    
    const refreshToken = () => {
        setRefreshLoading(true);

        if (hasNetwork) {
            const controller = new AbortController();
            axiosInstance.get(`/auth/refresh?appVersion=${process.env.REACT_APP_VERSION}`, { signal: controller.signal }).then(function (res) {
                if (res.data.code === 200) {
                    const newToken = res.data.data.token;
                    
                    CONF.SESSION_TOKEN ? 
                        sessionStorage.setItem('mecadrive_token', newToken) : 
                        localStorage.setItem('mecadrive_token', newToken);


                    setUser(res.data.data.user);
                    setRole(res.data.data.role);
                    setToken(newToken);
                    setIsLogged(true);
                    if (res.data.data.deviceLimitExeeded) {
                        setDeviceLimitExeeded(true);
                    }
                }
                else if (res.data.code === 426) {
                    M.toast({
                        html: `${t('an-update-is-required-to-be-able-to-use-the-online-mode')}<br />${t('your-version')}: ${process.env.REACT_APP_VERSION}<br />${t('last-version')}: ${res.data.error}`, 
                        classes: 'blue',
                        displayLength: 10000
                    });
                    offlineRefresh();
                }
                else {
                    sessionStorage.removeItem('mecadrive_token');
                    localStorage.removeItem('mecadrive_token');
                    
                    setUser(null);
                    setRole(null);
                    setToken(null);
                    setDeviceLimitExeeded(false);
                    setIsLogged(false);

                    if (res.data.code === 401) {
                        M.toast({html: t('session-expired-please-log-in-again') as string, classes: 'red'});
                    }
                    else {
                        M.toast({html: t('an-error-occurred-while-login') as string, classes: 'red'});
                    }
                }

                setRefreshLoading(false);
            }).catch(function (error) {
                if (error.code !== "ERR_CANCELED") {
                    console.log(error);
                    M.toast({html: t('server-unavailable') as string, classes: 'red'});
                    offlineRefresh();
                }
            });

            return controller;
        }
        else {
            offlineRefresh();
        }
        
        return null;
    };

    const offlineRefresh = async () => {
        if (token && localDbInfo.db_version !== null && localDbInfo.app_version === process.env.REACT_APP_VERSION) {
            const tokenInfo = parseJwt(token);
            if (Math.floor(Date.now() / 1000) <= tokenInfo.exp) {
                const localUser = await getLocalUser(tokenInfo.user_id);
                const localRole = localUser ? await getLocalRole(localUser.role_id) : null;
                setUser(localUser);
                setRole(localRole);
                setRefreshLoading(false);
            }
            else {
                setRefreshLoading(false);
            }
        }
        else {
            setRefreshLoading(false);
        }
    };

    const auth = (data: {login: string, password: string}) => {
        setAuthLoading(true);
        axiosInstance.post('/auth/login', {...data, appVersion: process.env.REACT_APP_VERSION}).then(function (res) {
            if (res.data.code === 200) {
                if (res.data.data !== false) {
                    const newToken = res.data.data.token;
                    
                    CONF.SESSION_TOKEN ? 
                        sessionStorage.setItem('mecadrive_token', newToken) : 
                        localStorage.setItem('mecadrive_token', newToken);

                    setUser(res.data.data.user);
                    setRole(res.data.data.role);
                    setToken(newToken);
                    setIsLogged(true);
                }
                else {
                    M.toast({html: 'Identifiants invalides', classes: 'red'});
                }
            }
            else if (res.data.code === 426) {
                M.toast({
                    html: `${t('an-update-is-required-to-be-able-to-use-the-online-mode')}<br />${t('your-version')}: ${process.env.REACT_APP_VERSION}<br />${t('last-version')}: ${res.data.error}`, 
                    classes: 'blue',
                    displayLength: 10000
                });
            }
            else {
                M.toast({html: 'Une erreur est survenue lors de la connexion', classes: 'red'});
            }

            setAuthLoading(false);
        }).catch(function (error) {
            if (error.code !== "ERR_CANCELED") {
                console.log(error);
                M.toast({html: 'Serveur indisponible', classes: 'red'});
                setAuthLoading(false);
            }
        });
    };

    const msalAuth = async (tokenResponse: AuthenticationResult | null) => {
        if (tokenResponse !== null) {
            setAuthLoading(true);

            const res = await axiosInstance.post('/auth/login', {token: tokenResponse.idToken, appVersion: process.env.REACT_APP_VERSION});
            
            if (res.data.code === 200 && res.data.data) {
                CONF.SESSION_TOKEN ? 
                    sessionStorage.setItem('mecadrive_token', res.data.data.token) : 
                    localStorage.setItem('mecadrive_token', res.data.data.token);

                setUser(res.data.data.user);
                setRole(res.data.data.role);
                setToken(res.data.data.token);
                setIsLogged(true);
            }
            else if (res.data.code === 426) {
                M.toast({
                    html: `${t('an-update-is-required-to-be-able-to-use-the-online-mode')}<br />${t('your-version')}: ${process.env.REACT_APP_VERSION}<br />${t('last-version')}: ${res.data.error}`, 
                    classes: 'blue',
                    displayLength: 10000
                });
            }
            else {
                //msalInstance.logoutRedirect({ account: msalInstance.getAccountByHomeId(tokenResponse.account.homeAccountId) });
                M.toast({html: t('login_error'), classes: 'red'});
            }
            
            setAuthLoading(false);
        }
    };

    const identify = (login: string) => {
        setAuthLoading(true);
        if (deviceToken) {
            axiosInstance.post('/auth/identify', {login: login, deviceToken: deviceToken, appVersion: process.env.REACT_APP_VERSION}).then(function (res) {
                
                if (res.data.code === 200) {
                    if (res.data.data !== false) {
                        const newToken = res.data.data.token;
                        
                        CONF.SESSION_TOKEN ? 
                            sessionStorage.setItem('mecadrive_token', newToken) : 
                            localStorage.setItem('mecadrive_token', newToken);

                        setUser(res.data.data.user);
                        setRole(res.data.data.role);
                        setToken(newToken);
                        setIsLogged(true);
                    }
                    else {
                        M.toast({html: 'Identifiants invalides', classes: 'red'});
                    }
                }
                else {
                    M.toast({html: t('login_error'), classes: 'red'});
                }
                setAuthLoading(false);
            }).catch(function (error) {
                if (error.code !== "ERR_CANCELED") {
                    console.log(error);
                    M.toast({html: 'Serveur indisponible', classes: 'red'});
                    setAuthLoading(false);
                }
            });
        }
    };

    const logout = () => {
        setLogoutLoading(true);

        axiosInstance.delete('/auth/logout').then(function(res) {
            if (res.data.code === 200) {
                sessionStorage.removeItem('mecadrive_token');
                localStorage.removeItem('mecadrive_token');
    
                setUser(null);
                setRole(null);
                setToken(null);
                setIsLogged(false);

                if (CONF.MSAL) {
                    msalInstance.logoutRedirect({ account: msalAccounts[0] });
                }
            }
            else {
                M.toast({html: t('logout_error'), classes: 'red'});
            }
            setLogoutLoading(false);
            
        }).catch(function (error) {
            console.log(error);
            M.toast({html: t('server-unavailable').toString(), classes: 'red'});
            setLogoutLoading(false);
        });
    };

    const isAuthorized = (authorization: string, action: "C" | "R" | "U" | "D") => {
        if (role && authorization in role.authorizations && (role.authorizations[authorization as string]).indexOf(action) !== -1) {
            return true;
        }
        return false;
    };

    useEffect(() => {
        let httpReq: AbortController | null = null;
        if (!isLogged && token !== null) {
            httpReq = refreshToken();
        }
        else {
            setRefreshLoading(false);
        }

        return () => {
            if (httpReq) {
                httpReq.abort();
            }
        };

    }, [hasNetwork]);

    useEffect(() => {
        if (CONF.MSAL && token === null) {
            msalInstance.handleRedirectPromise().then((tokenResponse) => {
                console.log(tokenResponse);
                msalAuth(tokenResponse);
            }).catch((error) => {
                console.error(error);
            });
        }
    }, []);

    return (
        <IdentityContext.Provider value={{
            user: user, 
            role: role, 
            isLogged: isLogged, 
            token: token, 
            auth: auth,
            msalAuth: msalAuth,
            identify: identify,
            logout: logout,
            authLoading: authLoading,
            refreshLoading: refreshLoading,
            logoutLoading: logoutLoading,
            deviceLimitExeeded: deviceLimitExeeded,
            isAuthorized: isAuthorized
        }} 
        {...props}>

            {refreshLoading ? 
                <div id="authenticating" className='full-width valign-wrapper'>
                    <h3 style={{color: '#fff', letterSpacing: '6px'}}>{t('authentication')}</h3>
                    <h1><i className="loading-spinner" style={{fontSize: '48px', color: '#fff'}}></i></h1>
                </div> : 
                props.children
            }

        </IdentityContext.Provider>
    );
}

export { IdentityProvider, useIdentity };

