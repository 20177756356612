import { useLocalData } from "../contexts/LocalDataContext";

const OfflineDataUpdate = () => {

    const { downloadingOfflineData } = useLocalData();

    return (
        downloadingOfflineData !== null ? <div style={{position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, backgroundColor: 'rgba(0, 0, 0, 0.3)', zIndex: 1002}}>
            <div className="bottom-modal" style={{display: 'block'}}>
                <p style={{marginTop: 0}}>Mise à jour des données hors-ligne en cours ({!downloadingOfflineData.downloading ? ((downloadingOfflineData.processed_tables * 100) / downloadingOfflineData.total_tables).toFixed() + '%' : 'Téléchargement...'}) </p>
                <div className="progress">
                    {downloadingOfflineData.downloading ? 
                        <div className="indeterminate"></div> : 
                        <div className="determinate" style={{width: ((downloadingOfflineData.processed_tables * 100) / downloadingOfflineData.total_tables) + '%'}}></div>
                    }
                </div>
            </div>
        </div> : null
    );
};

export default OfflineDataUpdate;