import { Suspense, lazy } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import DisconnectedRoute from './components/DisconnectedRoute';
import IdentifiedRoute from './components/IdentifiedRoute';
import Loading from './components/Loading';
import ProtectedRoute from './components/ProtectedRoute';
import './styles/css/themes.css';

const Home = lazy(() => import("./pages/Home"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const Trains = lazy(() => import("./pages/Trains"));
const RealTime = lazy(() => import("./pages/RealTime"));
const Trackings = lazy(() => import("./pages/Trackings"));
const Sections = lazy(() => import("./pages/Sections"));
const Lines = lazy(() => import("./pages/Lines"));
const Calendars = lazy(() => import("./pages/Calendars"));
const DutyTours = lazy(() => import("./pages/DutyTours"));
const Categories = lazy(() => import("./pages/Categories"));
const BrakeCodes = lazy(() => import("./pages/BrakeCodes"));
const Exports = lazy(() => import("./pages/Exports"));
const Users = lazy(() => import("./pages/Users"));
const Devices = lazy(() => import("./pages/Devices"));
const Messages = lazy(() => import("./pages/Messages"));
const Account = lazy(() => import("./pages/Account"));
const Signs = lazy(() => import("./pages/Signs"));
const SchedulesAdmin = lazy(() => import("./pages/SchedulesAdmin"));
const PdfExport = lazy(() => import("./pages/PdfExport"));
const DataValidation = lazy(() => import("./pages/DataValidation"));
const RouteboardColumns = lazy(() => import("./pages/RouteboardColumns"));
const Translations = lazy(() => import("./pages/Translations"));
const Roles = lazy(() => import("./pages/Roles"));
const Versions = lazy(() => import("./pages/Versions"));
const External = lazy(() => import("./pages/External"));
const Login = lazy(() => import("./pages/Login"));
const NotFound = lazy(() => import("./pages/NotFound"));

function App() {
    
    const router = createBrowserRouter([
        {
            path: '/',
            element: <IdentifiedRoute><Home /></IdentifiedRoute>,
            errorElement: <NotFound />
        },
        {
            path: '/dashboard/:date?/*',
            element: <IdentifiedRoute><Dashboard isOffline={false} /></IdentifiedRoute>
        },
        {
            path: '/trains/*',
            element: <ProtectedRoute><Trains /></ProtectedRoute>
        },
        {
            path: '/lines/*',
            element: <ProtectedRoute><Lines /></ProtectedRoute>
        },
        {
            path: '/sections/*',
            element: <ProtectedRoute><Sections /></ProtectedRoute>
        },
        {
            path: '/calendars/*',
            element: <ProtectedRoute><Calendars /></ProtectedRoute>
        },
        {
            path: '/users/*',
            element: <ProtectedRoute><Users /></ProtectedRoute>
        },
        {
            path: '/devices/*',
            element: <ProtectedRoute><Devices /></ProtectedRoute>
        },
        {
            path: '/account/*',
            element: <IdentifiedRoute><Account /></IdentifiedRoute>
        },
        {
            path: '/duty-tours/*',
            element: <ProtectedRoute><DutyTours /></ProtectedRoute>
        },
        {
            path: '/real-time/*',
            element: <ProtectedRoute><RealTime /></ProtectedRoute>
        },
        {
            path: '/messages/*',
            element: <ProtectedRoute><Messages /></ProtectedRoute>
        },
        {
            path: '/exports/*',
            element: <ProtectedRoute><Exports /></ProtectedRoute>
        },
        {
            path: '/schedules-admin/*',
            element: <ProtectedRoute><SchedulesAdmin /></ProtectedRoute>
        },
        {
            path: '/pdf-export/*',
            element: <ProtectedRoute><PdfExport /></ProtectedRoute>
        },
        {
            path: '/trackings/*',
            element: <ProtectedRoute><Trackings /></ProtectedRoute>
        },
        {
            path: '/categories/*',
            element: <ProtectedRoute><Categories /></ProtectedRoute>
        },
        {
            path: '/brake-codes/*',
            element: <ProtectedRoute><BrakeCodes /></ProtectedRoute>
        },
        {
            path: '/signs/*',
            element: <ProtectedRoute><Signs /></ProtectedRoute>
        },
        {
            path: '/roles/*',
            element: <ProtectedRoute><Roles /></ProtectedRoute>
        },
        {
            path: '/routeboard-columns/*',
            element: <ProtectedRoute><RouteboardColumns /></ProtectedRoute>
        },
        {
            path: '/translations/*',
            element: <ProtectedRoute><Translations /></ProtectedRoute>
        },
        {
            path: '/versions/*',
            element: <Versions />
        },
        {
            path: '/external/*',
            element: <ProtectedRoute><External /></ProtectedRoute>
        },
        {
            path: '/data-validation/*',
            element: <ProtectedRoute><DataValidation /></ProtectedRoute>
        },
        {
            path: '/login/*',
            element: <DisconnectedRoute><Login /></DisconnectedRoute>
        },
        {
            path: '*',
            element: <NotFound />
        }
    ]);

    return <Suspense fallback={<Loading />}>
        <RouterProvider router={router} />
    </Suspense>;
}





export default App;
