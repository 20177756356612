import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import M from 'materialize-css';
import { useEffect, type RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import AccounSettings from '../account/AccountSettings';

interface Props {
    sidesettingsRef: RefObject<HTMLDivElement>;
}

const SideSettings = (props: Props) => {
    const { t } = useTranslation();

    

    const { sidesettingsRef } = props;
    
    useEffect(() => {
        if (sidesettingsRef.current) {
            M.Sidenav.init(sidesettingsRef.current);
        }
    }, [sidesettingsRef]);
    
    const closeSidenav = () => {
        const sidesettingsInst = M.Sidenav.getInstance(sidesettingsRef.current as HTMLDivElement);
        if (sidesettingsInst.isOpen) {
            sidesettingsInst.close();
        }
    };

    return (
        <div className="sidenav sidesettings" ref={sidesettingsRef}>
            <nav>
                <div className="row">
                    <div className="col s12" style={{display: 'flex', alignItems: 'center'}}>
                        <h6 style={{margin: 0, flex: '1 1 auto'}}>{t('settings')}</h6>
                        <a href="#" className="right" onClick={closeSidenav}>
                            <FontAwesomeIcon icon={faTimes}/>
                        </a>
                    </div>
                </div>
            </nav>

            <AccounSettings />
        
        </div>
    );
};

export default SideSettings;