import { PropsWithChildren, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useIdentity } from "../contexts/IdentityContext";
import { useSettings } from "../contexts/SettingsContext";
import Nav from "./menus/Nav";
import SideMessages from "./menus/SideMessages";
import SideNav from "./menus/SideNav";
import SideSettings from "./menus/SideSettings";

const Layout = ({ children }: PropsWithChildren) => {

    const { t } = useTranslation();
    const { logoutLoading } = useIdentity();
    const { settings } = useSettings();

    const sidenavRef = useRef<HTMLDivElement>(null);
    const sidesettingsRef = useRef<HTMLDivElement>(null);
    const sidemessagesRef = useRef<HTMLDivElement>(null);
    
    return (logoutLoading ? <div id="authenticating" className='full-width valign-wrapper'>
        <h3 style={{color: '#fff', letterSpacing: '6px'}}>{t('logout')}</h3>
        <h1><i className="loading-spinner" style={{fontSize: '48px', color: '#fff'}}></i></h1>
    </div> : <>
        <Nav sidenavRef={sidenavRef} sidesettingsRef={sidesettingsRef} sidemessagesRef={sidemessagesRef} />

        <main style={{fontSize: (settings.font_size+'em')}}>
            <div className="container">
                {children}
            </div>
        </main>

        <SideNav sidenavRef={sidenavRef} />
        <SideSettings sidesettingsRef={sidesettingsRef} />
        <SideMessages sidemessagesRef={sidemessagesRef} />
    </>);
};

export default Layout;