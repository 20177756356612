import { type MouseEventHandler } from 'react';
import { type IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
    to: string;
    title: string;
    icon?: IconDefinition;
    onClick: MouseEventHandler
}

const SideLink = (props: Props) => {

    return (
        <NavLink 
            to={props.to} 
            className={({ isActive }) =>`waves-effect ${isActive ? 'active' : ''}`}
            onClick={props.onClick}
        >
            {props.title} 
            {props.icon && <span className="right text-center" style={{width: "30px"}}><FontAwesomeIcon icon={props.icon} size="lg" /></span>}
        </NavLink>
    );
};

export default SideLink;