import {
    faBullhorn,
    faBusinessTime,
    faCalendarAlt,
    faCaretDown,
    faClipboardCheck,
    faDotCircle,
    faFileExport,
    faFilePdf,
    faGripLinesVertical,
    faHome,
    faLanguage,
    faMapMarkedAlt,
    faPowerOff,
    faQuestion,
    faRoute,
    faSearchLocation,
    faShieldAlt,
    faSign,
    faSitemap,
    faSubway,
    faSuitcaseRolling,
    faSyncAlt,
    faTable,
    faTabletScreenButton,
    faTachometerAlt,
    faTimes,
    faUpload,
    faUserCircle,
    faUserGroup,
    faUsersCog
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import M from 'materialize-css';
import React, { useEffect, useRef, type RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useApp } from '../../contexts/AppContext';
import { useIdentity } from '../../contexts/IdentityContext';
import SideLink from './SideLink';

const SideNav = (props: {sidenavRef: RefObject<HTMLDivElement>}) => {
    const { t } = useTranslation();
    const { CONF } = useApp();
    const { token, logout, isAuthorized } = useIdentity();
    
    const { sidenavRef } = props;
    const optionsRef = useRef(null);

    useEffect(() => {
        if (sidenavRef.current) {
            M.Sidenav.init(sidenavRef.current);
        }
        if (optionsRef.current) {
            M.Collapsible.init(optionsRef.current);
        }
    }, []);
    
    const closeSidenav = () => {
        if (sidenavRef.current) {
            const sidenavInst = M.Sidenav.getInstance(sidenavRef.current);
            if (sidenavInst.isOpen) {
                sidenavInst.close();
            }
        }
    };

    const handleLogout = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        if (window.confirm("Êtes-vous sûr de vouloir vous déconnecter ?")) {
            logout();
        }
        return false;
    };

    return (
        <div className="sidenav" ref={sidenavRef}>
            <nav style={{marginBottom: '20px'}}>
                <div className="row">
                    <div className="col s12" style={{display: 'flex', alignItems: 'center'}}>
                        <h6 style={{margin: 0, flex: '1 1 auto'}}>{CONF.NAME}</h6>
                        <a href="#" className="right" onClick={closeSidenav}>
                            <FontAwesomeIcon icon={faTimes}/>
                        </a>
                    </div>
                </div>
            </nav>

            <ul className="collapsible" ref={optionsRef}>
                
                {isAuthorized("home", "R") ? <li>
                    <SideLink to="/" onClick={closeSidenav} title={t('home')} icon={faHome}/>
                </li> : null}
                {isAuthorized("dashboard", "R") ? <li>
                    <SideLink to="/dashboard" onClick={closeSidenav} title={t('dashboard')} icon={faTachometerAlt}/>
                </li> : null}
                {isAuthorized("account", "R") ? <li>
                    <SideLink to="/account/" onClick={closeSidenav} title={t('my-account')} icon={faUserCircle}/>
                </li> : null}
                <li>
                    <Link to={`https://help.mecadrive.ch?t=${token}`} target="_blank">
                        {t('help')} <span className="right text-center" style={{width: "30px"}}><FontAwesomeIcon icon={faQuestion} size="lg" /></span>
                    </Link>
                </li>
                <li>
                    <SideLink to="#" onClick={handleLogout} title={t('logout')} icon={faPowerOff}/>
                </li>
                <li>
                    <a className="collapsible-header waves-effect">
                        {t('network')} <span style={{height: '100%'}} className="right"><FontAwesomeIcon icon={faCaretDown} /></span>
                    </a>
                    <ul className="collapsible-body">
                        {isAuthorized("train", "R") ? <li>
                            <SideLink to="/trains" onClick={closeSidenav} title={t('trains')} icon={faSubway}/>
                        </li> : null}
                        {/*isAuthorized("step", "R") ? <li>
                            <SideLink to="/junctions" onClick={closeSidenav} title={t('junction-stations')} icon={faCodeBranch}/>
                        </li> : null*/}
                        {isAuthorized("section", "R") ? <li>
                            <SideLink to="/sections" onClick={closeSidenav} title={t('sections')} icon={faRoute}/>
                        </li> : null}
                        {isAuthorized("line", "R") ? <li>
                            <SideLink to="/lines" onClick={closeSidenav} title={t('lines')} icon={faGripLinesVertical}/>
                        </li> : null}
                    </ul>
                </li>
                <li>
                    <a className="collapsible-header waves-effect">
                        {t('planning')} <span style={{height: '100%'}} className="right"><FontAwesomeIcon icon={faCaretDown} /></span>
                    </a>
                    <ul className="collapsible-body">
                        {isAuthorized("calendar", "R") ? <li>
                            <SideLink to="/calendars" onClick={closeSidenav} title={t('calendars')} icon={faCalendarAlt}/>
                        </li> : null}
                    </ul>
                </li>
                <li>
                    <a className="collapsible-header waves-effect">
                        {t('organization')} <span style={{height: '100%'}} className="right"><FontAwesomeIcon icon={faCaretDown} /></span>
                    </a>
                    <ul className="collapsible-body">
                        {isAuthorized("user", "R") ? <li>
                            <SideLink to="/users" onClick={closeSidenav} title={t('users')} icon={faUsersCog}/>
                        </li> : null}
                        {isAuthorized("message", "C") ? <li>
                            <SideLink to="/messages" onClick={closeSidenav} title={t('messages')} icon={faBullhorn}/>
                        </li> : null}
                        {isAuthorized("duty_tour", "R") ? <li>
                            <SideLink to="/duty-tours" onClick={closeSidenav} title={t('duty-tours')} icon={faSitemap}/>
                        </li> : null}
                        {isAuthorized("category", "R") ? <li>
                            <SideLink to="/categories" onClick={closeSidenav} title={t('groups')} icon={faUserGroup}/>
                        </li> : null}
                        {isAuthorized("role", "R") ? <li>
                            <SideLink to="/roles" onClick={closeSidenav} title={t('roles')} icon={faShieldAlt}/>
                        </li> : null}
                        {(isAuthorized("device", "R") && CONF.DEVICE_MANAGEMENT) ? <li>
                            <SideLink to="/devices" onClick={closeSidenav} title={t('devices')} icon={faTabletScreenButton}/>
                        </li> : null}
                    </ul>
                </li>
                {CONF.EXTERNAL_SYSTEM !== null ? <li>
                    <a className="collapsible-header waves-effect">
                        {t('synchronizations')} <span style={{height: '100%'}} className="right"><FontAwesomeIcon icon={faCaretDown} /></span>
                    </a>
                    <ul className="collapsible-body">
                        {/*isAuthorized("external_binding", "R") ? <li>
                            <SideLink to="/external/bindings/train" onClick={closeSidenav} title="IDs de liaison" icon={faLink}/>
                        </li> : null*/}
                        {isAuthorized("external_synchronization", "R") ? <li>
                            <SideLink to="/external/synchronizations" onClick={closeSidenav} title={CONF.EXTERNAL_SYSTEM} icon={faSyncAlt}/>
                        </li> : null}
                        {isAuthorized("external_synchronization", "R") ? <li>
                            <SideLink to="/external/duty-tours" onClick={closeSidenav} title={t('duty-tours')} icon={faUpload}/>
                        </li> : null}
                    </ul>
                </li> : null}
                <li>
                    <a className="collapsible-header waves-effect">
                        {t('tools')} <span style={{height: '100%'}} className="right"><FontAwesomeIcon icon={faCaretDown} /></span>
                    </a>
                    <ul className="collapsible-body">
                        {isAuthorized("real_time", "R") ? <li>
                            <SideLink to="/real-time" onClick={closeSidenav} title={t('real-time')} icon={faMapMarkedAlt}/>
                        </li> : null}
                        {isAuthorized("tracking", "R") ? <li>
                            <SideLink to="/trackings" onClick={closeSidenav} title={`${t('trackings')} (beta)`} icon={faSearchLocation}/>
                        </li> : null}
                        {isAuthorized("export", "R") ? <li>
                            <SideLink to="/exports" onClick={closeSidenav} title={t('exports')} icon={faFileExport}/>
                        </li> : null}
                        {isAuthorized("trip", "R") ? <li>
                            <SideLink to="/trips" onClick={closeSidenav} title={t('trips')} icon={faSuitcaseRolling}/>
                        </li> : null}
                        {isAuthorized("schedule_admin", "R") ? <li>
                            <SideLink to="/schedules-admin" onClick={closeSidenav} title={t('schedules-admin')} icon={faBusinessTime}/>
                        </li> : null}
                        {isAuthorized("data_validation", "R") ? <li>
                            <SideLink to="/data-validation" onClick={closeSidenav} title={t('data-validation')} icon={faClipboardCheck}/>
                        </li> : null}
                        {isAuthorized("pdf_export", "R") ? <li>
                            <SideLink to="/pdf-export" onClick={closeSidenav} title={t('pdf-export')} icon={faFilePdf}/>
                        </li> : null}
                        {/*<li>
                            <SideLink to="/help" onClick={closeSidenav} title="Aide" icon={faQuestion}/>
                        </li>*/}
                    </ul>
                </li>
                <li>
                    <a className="collapsible-header waves-effect">
                        {t('configuration')} <span style={{height: '100%'}} className="right"><FontAwesomeIcon icon={faCaretDown} /></span>
                    </a>
                    <ul className="collapsible-body">
                        
                        {isAuthorized("brake_code", "R") ? <li>
                            <SideLink to="/brake-codes" onClick={closeSidenav} title={t('brake-categories')} icon={faDotCircle}/>
                        </li> : null}
                        {isAuthorized("sign", "R") ? <li>
                            <SideLink to="/signs" onClick={closeSidenav} title={t('signals')} icon={faSign}/>
                        </li> : null}
                        {isAuthorized("routeboard_column", "R") ? <li>
                            <SideLink to="/routeboard-columns" onClick={closeSidenav} title={t('radn')} icon={faTable}/>
                        </li> : null}
                        {isAuthorized("translation", "R") ? <li>
                            <SideLink to="/translations" onClick={closeSidenav} title={t('translations')} icon={faLanguage}/>
                        </li> : null}
                    </ul>
                </li>
            </ul>
        
        </div>
    );
};

export default SideNav;