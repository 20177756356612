import {
    faBars,
    faBullhorn,
    faCalendarAlt,
    faCloud,
    faCog,
    faCommentAlt,
    faDatabase,
    faFileExport,
    faGripLinesVertical,
    faHome,
    faMapMarkedAlt,
    faPowerOff,
    faQuestion,
    faRoute,
    faSitemap,
    faSubway,
    faTabletScreenButton,
    faTachometerAlt,
    faUserCircle,
    faUsersCog
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import M from 'materialize-css';
import { useEffect, useState, type RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';
import { useApp } from '../../contexts/AppContext';
import { useIdentity } from '../../contexts/IdentityContext';
import { useLocalData } from '../../contexts/LocalDataContext';
import { useMessages } from '../../contexts/MessagesContext';
import { useSettings } from '../../contexts/SettingsContext';

interface Props {
    sidemessagesRef: RefObject<HTMLDivElement>;
    sidenavRef: RefObject<HTMLDivElement>;
    sidesettingsRef: RefObject<HTMLDivElement>;
}

const Nav = (props: Props) => {
    const { t } = useTranslation();

    const { CONF } = useApp();
    const { upToDate } = useLocalData();
    const { token, logout, isAuthorized } = useIdentity();
    const { settings } = useSettings();
    const { messages } = useMessages();

    const { sidemessagesRef, sidenavRef, sidesettingsRef } = props;

    const [newMsgCount, setNewMsgCount] = useState<number>(0);

    useEffect(() => {
        const tooltips = M.Tooltip.init(document.querySelectorAll('.nav-tooltipped'), {
            margin: 0,
            exitDelay: 0
        });

        return () => {
            for (const tooltip of tooltips) {
                tooltip.destroy();
            }
        };

    }, []);

    useEffect(() => {
        setNewMsgCount(messages.filter(message => !message.is_read).length);
    }, [messages]);

    const openSidenav = (e: React.MouseEvent<HTMLAnchorElement>, ref: RefObject<HTMLDivElement>) => {
        e.preventDefault();
        const inst = M.Sidenav.getInstance(ref.current as HTMLDivElement);
        if (!inst.isOpen) {
            inst.open();
        }
    };

    const handleLogout = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        if (window.confirm("Êtes-vous sûr de vouloir vous déconnecter ?")) {
            logout();
        }
        return false;
    };

    return (
        <nav id="main-nav">
            <div className="nav-wrapper">
                <div className="row">
                    <div className="col s12">
                        <ul>
                            <li>
                                <a href="#" onClick={(e) => openSidenav(e, sidenavRef)}>
                                    <FontAwesomeIcon icon={faBars}/>
                                </a>
                            </li>
                            <li>
                                <a href="#" onClick={(e) => openSidenav(e, sidesettingsRef)}>
                                    <FontAwesomeIcon icon={faCog}/>
                                </a>
                            </li>
                            <li>
                                <a href="#" onClick={(e) => openSidenav(e, sidemessagesRef)}>
                                    <FontAwesomeIcon icon={faCommentAlt}/>{newMsgCount ? <span className="new badge" style={{minWidth: 'auto'}}>{newMsgCount}</span> : null}
                                </a>
                            </li>
                        </ul>

                        <ul className="right hide-on-small-only">
                            {isAuthorized("home", "R") ? 
                                <li>
                                    <NavLink to="/" className={({ isActive }) =>`nav-tooltipped ${isActive ? 'active' : ''}`} data-tooltip={t('home')}>
                                        <FontAwesomeIcon icon={faHome}/>
                                    </NavLink>
                                </li> : null}
                            {isAuthorized("dashboard", "R") ? 
                                <li style={{position: 'relative'}}>
                                    <NavLink to={`/dashboard`} className={({ isActive }) =>`nav-tooltipped ${isActive ? 'active' : ''}`} data-tooltip={t('dashboard')}>
                                        <FontAwesomeIcon icon={faTachometerAlt}/>
                                        <span style={{position: 'absolute', right: '6px', top: !settings.local_mode ? '10px' : 'unset', bottom: settings.local_mode ? '10px' : 'unset', lineHeight: 'normal', fontSize: '0.6rem'}}>
                                            <FontAwesomeIcon icon={!settings.local_mode ? faCloud : faDatabase}/>
                                        </span>
                                    </NavLink>
                                </li> : null}
                            {isAuthorized("real_time", "R") ?
                                <li className="hide-on-med-and-down">
                                    <NavLink to="/real-time" className={({ isActive }) =>`nav-tooltipped ${isActive ? 'active' : ''}`} data-tooltip={t('real-time')}>
                                        <FontAwesomeIcon icon={faMapMarkedAlt}/>
                                    </NavLink>
                                </li> : null}
                            {isAuthorized("train", "R") ? 
                                <li className="hide-on-med-and-down">
                                    <NavLink to="/trains" className={({ isActive }) =>`nav-tooltipped ${isActive ? 'active' : ''}`} data-tooltip={t('trains')}>
                                        <FontAwesomeIcon icon={faSubway}/>
                                    </NavLink>
                                </li> : null}
                            {isAuthorized("section", "R") ? 
                                <li className="hide-on-med-and-down">
                                    <NavLink to="/sections" className={({ isActive }) =>`nav-tooltipped ${isActive ? 'active' : ''}`} data-tooltip={t('sections')}>
                                        <FontAwesomeIcon icon={faRoute}/>
                                    </NavLink>
                                </li> : null}
                            {isAuthorized("line", "R") ? 
                                <li className="hide-on-med-and-down">
                                    <NavLink to="/lines" className={({ isActive }) =>`nav-tooltipped ${isActive ? 'active' : ''}`} data-tooltip={t('lines')}>
                                        <FontAwesomeIcon icon={faGripLinesVertical}/>
                                    </NavLink>
                                </li> : null}
                            {isAuthorized("calendar", "R") ? 
                                <li className="hide-on-med-and-down">
                                    <NavLink to="/calendars" className={({ isActive }) =>`nav-tooltipped ${isActive ? 'active' : ''}`} data-tooltip={t('calendars')}>
                                        <FontAwesomeIcon icon={faCalendarAlt}/>
                                    </NavLink>
                                </li> : null}
                            {isAuthorized("user", "R") ? 
                                <li className="hide-on-med-and-down">
                                    <NavLink to="/users" className={({ isActive }) =>`nav-tooltipped ${isActive ? 'active' : ''}`} data-tooltip={t('users')}>
                                        <FontAwesomeIcon icon={faUsersCog}/>
                                    </NavLink>
                                </li> : null}
                            {(isAuthorized("device", "R") && CONF.DEVICE_MANAGEMENT) ? 
                                <li className="hide-on-med-and-down">
                                    <NavLink to="/devices" className={({ isActive }) =>`nav-tooltipped ${isActive ? 'active' : ''}`} data-tooltip={t('devices')}>
                                        <FontAwesomeIcon icon={faTabletScreenButton}/>
                                    </NavLink>
                                </li> : null}
                            {isAuthorized("message", "C") ? 
                                <li className="hide-on-large-and-down">
                                    <NavLink to="/messages" className={({ isActive }) =>`nav-tooltipped ${isActive ? 'active' : ''}`} data-tooltip={t('messages')}>
                                        <FontAwesomeIcon icon={faBullhorn}/>
                                    </NavLink>
                                </li> : null}
                            {isAuthorized("duty_tour", "R") ? 
                                <li className="hide-on-large-and-down">
                                    <NavLink to="/duty-tours" className={({ isActive }) =>`nav-tooltipped ${isActive ? 'active' : ''}`} data-tooltip={t('duty-tours')}>
                                        <FontAwesomeIcon icon={faSitemap}/>
                                    </NavLink>
                                </li> : null}
                            {isAuthorized("export", "R") ? 
                                <li className="hide-on-large-and-down">
                                    <NavLink to="/exports" className={({ isActive }) =>`nav-tooltipped ${isActive ? 'active' : ''}`} data-tooltip={t('exports')}>
                                        <FontAwesomeIcon icon={faFileExport}/>
                                    </NavLink>
                                </li> : null}
                            {isAuthorized("account", "R") ? 
                                <li>
                                    <NavLink to="/account/" className={({ isActive }) =>`nav-tooltipped ${isActive ? 'active' : ''}`} data-tooltip={t('my-account')}>
                                        <FontAwesomeIcon icon={faUserCircle}/>
                                        {!upToDate ? <span className="new badge">{t('update-available')}</span> : null}
                                    </NavLink>
                                </li> : null}
                            <li>
                                <Link to={`https://help.mecadrive.ch?t=${token}`} target="_blank" className="nav-tooltipped" data-tooltip={t('help')}>
                                    <FontAwesomeIcon icon={faQuestion} />
                                </Link>
                            </li>
                            <li>
                                <a href="#" className="nav-tooltipped" data-tooltip={t('logout')} onClick={handleLogout}>
                                    <FontAwesomeIcon icon={faPowerOff}/>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Nav;