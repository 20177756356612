import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { AppProvider } from './contexts/AppContext';
import { DeviceProvider } from './contexts/DeviceContext';
import { IdentityProvider } from './contexts/IdentityContext';
import { LocalDataProvider } from './contexts/LocalDataContext';
import { MessagesProvider } from './contexts/MessagesContext';
import { SettingsProvider } from './contexts/SettingsContext';
import { SocketProvider } from './contexts/SocketContext';
import { SoundsProvider } from './contexts/SoundsContext';
import './i18n';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const UpdateSW = () => {
    return (<>
        <div id="update-modal" className="bottom-modal">
            <p style={{marginTop: 0}}>Une mise à jour de l'application est disponible</p>
            <button className="btn" onClick={() => window.location.href = window.location.href}>Mettre à jour</button>
        </div>
        <div id="cached-modal" className="bottom-modal">
            <p style={{marginTop: 0}}>L'application a été mise en cache pour une utilisation sans connexion</p>
            <button className="btn" onClick={(e) => (e.currentTarget.parentElement as HTMLDivElement).style.display = 'none'}>OK</button>
        </div>
    </>);
};

const root = createRoot(document.getElementById('root') as HTMLDivElement);
root.render(<React.StrictMode>
    <AppProvider>
        <LocalDataProvider>
            <DeviceProvider>
                <IdentityProvider>
                    <SocketProvider>
                        <SettingsProvider>
                            <SoundsProvider>
                                <MessagesProvider>
                                    <App />
                                </MessagesProvider>
                            </SoundsProvider>
                        </SettingsProvider>
                    </SocketProvider>
                </IdentityProvider>
            </DeviceProvider>
        </LocalDataProvider>
    </AppProvider>
    
    <UpdateSW />
</React.StrictMode>);

serviceWorkerRegistration.register({ 
    onUpdate: (registration: ServiceWorkerRegistration) => {
        registration.waiting?.postMessage({ type: 'SKIP_WAITING' }); 
        (document.getElementById("update-modal") as HTMLDivElement).style.display = 'block';
    },
    onSuccess: () => { 
        (document.getElementById("cached-modal") as HTMLDivElement).style.display = 'block';
    }
});