const Loading = () => {

    return (
        <div className="row">
            <div className="col s12 center-align">
                <h1 style={{marginTop: '60px'}}><i className="loading-spinner" style={{fontSize: '48px'}}></i></h1>
            </div>
        </div>
    );
};

export default Loading;