import { createContext, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loading from '../components/Loading';
import { type Device } from '../types';
import axiosInstance from '../utils/axiosSetup';
import { getLocalDevice } from '../utils/localDb';
import parseJwt from '../utils/parseJWT';
import { useApp } from './AppContext';
import { useLocalData } from './LocalDataContext';

interface Props {
    registerLoading: boolean;
    device: Device | null;
    isRegistered: boolean;
    deviceToken: string | null;
    register: Function;
    unregister: Function;
}

const DeviceContext = createContext<Props>({
    registerLoading: false, 
    device: null, 
    isRegistered: false,
    deviceToken: null,
    register: () => {},
    unregister: () => {}
});

const useDevice = () => {
    const currentDeviceContext = useContext(DeviceContext);
    if (!currentDeviceContext) {
        throw new Error(
            "useDevice has to be used within <DeviceContext.Provider>"
        );
    }
    
    return currentDeviceContext;
};

function DeviceProvider(props: {children: JSX.Element}) {
    const { t } = useTranslation();

    const { hasNetwork } = useApp();
    const { localDbInfo } = useLocalData();

    const [registerLoading, setRegisterLoading] = useState<boolean>(false);
    const [refreshLoading, setRefreshLoading] = useState<boolean>(true);
    const [device, setDevice] = useState<Device | null>(null);
    const [isRegistered, setIsRegistered] = useState<boolean>(false);
    const [deviceToken, setDeviceToken] = useState<string | null>(localStorage.getItem('mecadrive_device_token'));

    const register = (key: string) => {
        setRegisterLoading(true);
        axiosInstance.post('/devices/register', {key, appVersion: process.env.REACT_APP_VERSION}).then(function (res) {
            if (res.data.code === 200) {
                if (res.data.data !== false) {
                    localStorage.setItem('mecadrive_device_token', res.data.data.token);

                    setDevice(res.data.data.device);
                    setDeviceToken(res.data.data.token);
                    setIsRegistered(true);
                }
                else {
                    M.toast({html: 'Identifiants invalides', classes: 'red'});
                }
            }
            else if (res.data.code === 426) {
                M.toast({
                    html: `${t('an-update-is-required-to-be-able-to-use-the-online-mode')}<br />${t('your-version')}: ${process.env.REACT_APP_VERSION}<br />${t('last-version')}: ${res.data.error}`, 
                    classes: 'blue',
                    displayLength: 10000
                });
            }
            else {
                M.toast({html: 'Une erreur est survenue lors de l\'enregistrement', classes: 'red'});
            }

            setRegisterLoading(false);
        }).catch(function (error) {
            if (error.code !== "ERR_CANCELED") {
                console.log(error);
                M.toast({html: 'Serveur indisponible', classes: 'red'});
                setRegisterLoading(false);
            }
        });
    };

    const refreshDeviceToken = () => {
        setRefreshLoading(true);

        if (hasNetwork) {
            const controller = new AbortController();
            axiosInstance.post(`/devices/refresh`, {token: deviceToken}, { signal: controller.signal }).then(function (res) {
                if (res.data.code === 200) {
                    setDevice(res.data.data.device);
                    setIsRegistered(true);
                }
                else {
                    localStorage.removeItem('mecadrive_device_token');
                    
                    setDevice(null);
                    setIsRegistered(false);

                    if (res.data.code === 401) {
                        M.toast({html: t('device-registration-expired') as string, classes: 'red'});
                    }
                    else {
                        M.toast({html: t('an-error-occurred-while-verifying-registration') as string, classes: 'red'});
                    }
                }

                setRefreshLoading(false);
            }).catch(function (error) {
                if (error.code !== "ERR_CANCELED") {
                    console.log(error);
                    M.toast({html: t('server-unavailable') as string, classes: 'red'});
                    offlineRefresh();
                }
            });

            return controller;
        }
        else {
            offlineRefresh();
        }

        return null;
    };

    const offlineRefresh = async () => {
        if (deviceToken && localDbInfo.db_version !== null && localDbInfo.app_version === process.env.REACT_APP_VERSION) {
            const tokenInfo = parseJwt(deviceToken);
            const localDevice = await getLocalDevice(tokenInfo.device_id);
            if (localDevice && localDevice.registration?.id === tokenInfo.jti) {
                setDevice(localDevice);
            }
            
            setRefreshLoading(false);
        }
        else {
            setRefreshLoading(false);
        }
    };

    const unregister = () => {
        
    };

    useEffect(() => {
        let httpReq: AbortController | null = null;
        if (!isRegistered && deviceToken !== null) {
            httpReq = refreshDeviceToken();
        }
        else {
            setRefreshLoading(false);
        }

        return () => {
            if (httpReq) {
                httpReq.abort();
            }
        };
    }, []);

    return (
        <DeviceContext.Provider value={{
            registerLoading: registerLoading, 
            device: device, 
            isRegistered: isRegistered,
            deviceToken: deviceToken,
            register: register,
            unregister: unregister
        }} 
        {...props}>

            {refreshLoading ? <Loading /> : props.children}

        </DeviceContext.Provider>
    );
}

export { DeviceProvider, useDevice };

