import { type MomentLocale } from "../../types";

const momentLocalesFr: MomentLocale = {
    months: [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre"
    ],
    monthsShort : [
        "janv.", 
        "févr.", 
        "mars", 
        "avr.", 
        "mai", 
        "juin", 
        "juil.", 
        "août", 
        "sept.", 
        "oct.", 
        "nov.", 
        "déc."
    ],
    weekdays : [
        "dimanche", 
        "lundi", 
        "mardi", 
        "mercredi", 
        "jeudi", 
        "vendredi", 
        "samedi"
    ],
    weekdaysShort : [
        "dim.", 
        "lun.", 
        "mar.", 
        "mer.", 
        "jeu.", 
        "ven.", 
        "sam."
    ],
    weekdaysMin : [
        "Di", 
        "Lu", 
        "Ma", 
        "Me", 
        "Je", 
        "Ve", 
        "Sa"
    ],
    weekdaysAbbrev : [
        "Di", 
        "Lu", 
        "Ma", 
        "Me", 
        "Je", 
        "Ve", 
        "Sa"
    ]
}

export default momentLocalesFr;