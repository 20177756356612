import Bowser from "bowser";
import M from 'materialize-css';
import { PropsWithChildren, useEffect, useState } from 'react';

interface SelectProps {
    className?: string;
    id: string;
    value: string | string[];
    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    label?: string;
    disabled?: boolean;
    multiple?: boolean;
}

const SelectField = (props: PropsWithChildren<SelectProps>) => {

    const browser = Bowser.parse(navigator.userAgent);
    const { className, id, value, onChange, children, label, disabled } = props;

    const [instance, setInstance] = useState<M.FormSelect | null>(null);

    const multiple = props.multiple ? true : false;

    useEffect(() => {
        if (id) {
            if (!props.multiple || (instance === null || instance?.dropdown?.isOpen === false)) {
                setInstance(M.FormSelect.init(document.querySelector(`select#${id}`) as Element, {dropdownOptions:{container:document.querySelector(`div#root main`) as Element}}));
            }
        }
    }, [id, value]);

    return (<div className="select-wrapper">
        <select id={id} value={value !== undefined ? value : 0} className={`${className ? className : ""} ${['iOS', 'Android'].includes(browser.os.name as string) ? " browser-default" : ""}`} onChange={onChange} multiple={multiple} disabled={disabled}>
            {children}
        </select>
        {label ? <label>{label}</label> : null}
    </div>);
};

export default SelectField;