import M from 'materialize-css';
import { type DownloadingOfflineData, type LocalDBData, type LocalDbInfo } from '../types';
import axiosInstance from './axiosSetup';
import { createLocalDb } from './localDb';

const downloadOffline = function(
    setDownloadingOfflineData: React.Dispatch<DownloadingOfflineData | null>, 
    setLocalDbInfo: React.Dispatch<LocalDbInfo>, 
    version: number | null = null
) {
    setDownloadingOfflineData({
        downloading: true,
        total_tables: 0,
        processed_tables: 0
    });
    
    axiosInstance.get(`offline/download${version !== null ? ('/'+version) : ''}`)
        .then(async function (res) {
            if (res.data.code === 200) {
                const localDBData: LocalDBData = res.data.data.offline_database;
                
                await createLocalDb(
                    localDBData.db_version, 
                    localDBData.data,
                    setDownloadingOfflineData
                );

                localStorage.setItem('mecadrive_offline', JSON.stringify({
                    db_version: localDBData.db_version,
                    app_version: localDBData.app_version
                }));

                setLocalDbInfo({
                    db_version: localDBData.db_version,
                    app_version: localDBData.app_version
                });

                setDownloadingOfflineData(null);
            }
            else {
                M.toast({html: 'Une erreur est survenue lors du téléchargement', classes: 'red'});
            }

            setDownloadingOfflineData(null);
        }).catch(function (error) {
            console.log(error);
            M.toast({html: 'Serveur indisponible', classes: 'red'});
            setDownloadingOfflineData(null);
        });
};

export default downloadOffline;