import { createContext, useContext, useEffect, useState } from 'react';
import OfflineDataUpdate from '../components/OfflineDataUpdate';
import { type DownloadingOfflineData, type LocalDbInfo } from '../types';
import axiosInstance from '../utils/axiosSetup';
import { getLocalUnsyncTrackings, setLocalTrackings } from '../utils/localDb';
import { useApp } from './AppContext';

interface Props {
    localDataLoading: boolean;
    localDbInfo: LocalDbInfo;
    setLocalDbInfo: React.Dispatch<LocalDbInfo>;
    upToDate: boolean;
    setUpToDate: React.Dispatch<boolean>;
    downloadingOfflineData: DownloadingOfflineData | null;
    setDownloadingOfflineData: React.Dispatch<DownloadingOfflineData | null>;
    sendTrackings: Function;
}

const LocalDataContext = createContext<Props | null>(null);

const useLocalData = () => {
    const currentLocalDataContext = useContext(LocalDataContext);
    if (!currentLocalDataContext) {
        throw new Error(
            "useLocalData has to be used within <LocalDataContext.Provider>"
        );
    }
    
    return currentLocalDataContext;
};

function LocalDataProvider(props: {children: JSX.Element}) {
    
    const { hasNetwork } = useApp();
	
    const [loading, setLoading] = useState<boolean>(true);
    const [upToDate, setUpToDate] = useState<boolean>(true);
    const [localDbInfo, setLocalDbInfo] = useState<LocalDbInfo>(JSON.parse(localStorage.getItem('mecadrive_offline') || '{"db_version": null,"app_version": null}'));
    const [downloadingOfflineData, setDownloadingOfflineData] = useState<DownloadingOfflineData | null>(null);


    const isUpToDate = (localDbInfo: LocalDbInfo) => {
        const controller = new AbortController();
        axiosInstance.get(`offline/up-to-date${localDbInfo.db_version !== null ? ('/'+localDbInfo.db_version) : ''}`, { signal: controller.signal }).then(function (res) {
            if (res.data.code === 200 && res.data.data.upToDate === false) {
                setUpToDate(false);
            }
            else {
                setUpToDate(true);
            }
        }).catch(function (error) {
            if (error.code !== "ERR_CANCELED") {
                console.log(error);
                setUpToDate(true);
            }
        });

        return controller;
    };

    const sendTrackings = async () => {
        const trackings = await getLocalUnsyncTrackings();

        const controller = new AbortController();
        if (trackings.length) {
            axiosInstance.post(`trackings`, trackings, { signal: controller.signal }).then(function (res) {
                if (res.data.code === 200) {
                    for (const key in res.data.data) {
                        const tracking = trackings.find(tracking => tracking.id === res.data.data[key]);
                        if (tracking) {
                            setLocalTrackings({...tracking, sync: true});
                        }
                    }
                }
            });
        }
        
        return controller;
    };

    useEffect(() => {
        let httpReq: Promise<AbortController> | null = null;
        if (hasNetwork && localDbInfo.db_version !== null) {
            httpReq = sendTrackings();
        }

        return () => {
            if (httpReq) {
                httpReq.then(controller => controller.abort());
            }
        };
    }, [hasNetwork]);

    useEffect(() => {
        let httpReq: AbortController | null = null;
        if (hasNetwork) {
            httpReq = isUpToDate(localDbInfo);
        }
        else {
            setUpToDate(true);
        }

        return () => {
            if (httpReq) {
                httpReq.abort();
            }
        };
    }, [localDbInfo, hasNetwork]);

    return (
        <LocalDataContext.Provider value={{ 
            localDataLoading: loading,
            upToDate: upToDate,
            setUpToDate: setUpToDate,
            localDbInfo: localDbInfo,
            setLocalDbInfo: setLocalDbInfo,
            downloadingOfflineData,
            setDownloadingOfflineData,
            sendTrackings
        }} 
        {...props}>

            {<>
                <OfflineDataUpdate />
                {props.children}
            </>}

        </LocalDataContext.Provider>
    );
}

export { LocalDataProvider, useLocalData };

