import { faBroadcastTower, faCheck, faCircleDot, faClock, faCloud, faDatabase, faExchangeAlt, faGauge, faInfoCircle, faLanguage, faLightbulb, faLocationArrow, faLocationDot, faMap, faMapMarkedAlt, faMinus, faMoon, faPlay, faPlus, faPowerOff, faRedoAlt, faStopwatch, faSyncAlt, faTextHeight, faTimes, faVolumeHigh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import M from 'materialize-css';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useApp } from '../../contexts/AppContext';
import { useIdentity } from '../../contexts/IdentityContext';
import { useSettings } from '../../contexts/SettingsContext';
import { useSocket } from '../../contexts/SocketContext';
import { type Settings } from '../../types';
import SelectField from '../forms/fields/Select';

const AccountSettings = () => {
    const { t, i18n } = useTranslation();

    const { CONF, geolocationGranted } = useApp();
    const { isAuthorized, role } = useIdentity();
    const { socketIO } = useSocket();
    const { settings, setSettings } = useSettings();

    useEffect(() => {
        M.Tooltip.init(document.querySelectorAll('.user-settings .tooltipped'), {
            margin: 0,
            exitDelay: 0
        });
    }, []);

    useEffect(() => {
        if (CONF.LANGS.length > 1) {
            M.FormSelect.init((document.querySelector('select#lang') as HTMLElement), {dropdownOptions:{container:document.body}});
        }
    }, [i18n.language]);

    const handleChangeFontSize = (increase: boolean) => {
        const fontSize = (increase ? parseFloat(((settings.font_size || 1) + 0.1).toFixed(1)) : parseFloat(((settings.font_size || 1) - 0.1).toFixed(1)) );
        setSettings({...settings, font_size: fontSize || 1});
    };

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSettings({...settings, [e.target.id]: (settings[e.target.id as keyof Settings] ? false : true)});
    };

    const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSettings({...settings, [e.target.id]: e.target.value});
    };

    const handleChangeDelay = (param: 'automatic_train_change_delay' | 'automatic_logout_delay', increase: boolean) => {
        const delay = param in settings ? (increase ? ((settings[param] || 1) + 1) : ((settings[param] || 1) - 1)) : 1;
        setSettings({...settings, [param]: delay});
    };

    return (
        <>
            <ul className="collection user-settings">

                <li className="collection-item">
                    <h5 style={{marginBottom: 0}}>{t('interface')}</h5>
                </li>
                
                <li className="collection-item">
                    <FontAwesomeIcon icon={faBroadcastTower} className="fa-fw" style={{marginRight: '10px'}} /> 
                    <span>{t('real-time')}</span>
                    <span className="right">
                        <div>
                            <span style={{fontSize: '0.6em', float: 'left'}}>{(socketIO && socketIO.connected) ? t('connected') : t('disconnected')}</span>
                            <div style={{
                                height: '12px',
                                width: '12px',
                                borderRadius: '12px',
                                marginTop: '5px',
                                marginLeft: '5px',
                                float: 'right',
                                backgroundColor: (socketIO && socketIO.connected) ? 'green' : 'transparent',
                                border: (socketIO && socketIO.connected) ? 'none' : '1px solid grey'
                            }}>
                            </div>
                        </div>
                    </span>
                </li>

                <li className="collection-item">
                    <span className="tooltipped" data-tooltip="Permission de géolocalisation">
                        <FontAwesomeIcon icon={faLocationArrow} className="fa-fw" style={{marginRight: '10px'}} /> 
                        <span>{t('geolocation')}</span>
                    </span>
                    <span className="right">
                        <div className="switch">
                            <div>
                                <span style={{fontSize: '0.6em', float: 'left'}}>{(geolocationGranted) ? t('granted') : t('denied')}</span>
                                <div style={{
                                    height: '12px',
                                    width: '12px',
                                    borderRadius: '12px',
                                    marginTop: '5px',
                                    marginLeft: '5px',
                                    float: 'right',
                                    backgroundColor: (geolocationGranted) ? 'green' : 'red'
                                }}>
                                </div>
                            </div>
                        </div>
                    </span>
                </li>
                
                {CONF.LANGS.length > 1 ? <li className="collection-item" style={{lineHeight: '3.5rem'}}>
                    <FontAwesomeIcon icon={faLanguage} className="fa-fw" style={{marginRight: '10px'}} /> 
                    <span>{t('language')}</span>
                    <span className="right">
                        <SelectField id="lang" value={settings.lang} onChange={handleSelectChange} disabled={role?.settings.lang.locked}>
                            {CONF.LANGS.includes('fr') ? <option key={'fr'} value={'fr'}>{t('french')}</option> : null}
                            {CONF.LANGS.includes('de') ? <option key={'de'} value={'de'}>{t('german')}</option> : null}
                            {CONF.LANGS.includes('en') ? <option key={'en'} value={'en'}>{t('english')}</option> : null}
                        </SelectField>
                    </span>
                </li> : null}

                <li className="collection-item">
                    <FontAwesomeIcon icon={faVolumeHigh} className="fa-fw" style={{marginRight: '10px'}} /> 
                    <span>{t('sounds')}</span>
                    <span className="right">
                        {role?.settings.sounds.locked ? <FontAwesomeIcon icon={settings.sounds ? faCheck : faTimes} /> : <div className="switch">
                            <label>
                                <input id="sounds" type="checkbox" checked={settings.sounds} onChange={handleCheckboxChange} />
                                <span className="lever"></span>
                            </label>
                        </div>}
                    </span>
                </li>

                <li className="collection-item">
                    <FontAwesomeIcon icon={faMoon} className="fa-fw" style={{marginRight: '10px'}} /> 
                    <span>{t('dark-mode')}</span>
                    <span className="right">
                        {role?.settings.night_mode.locked ? <FontAwesomeIcon icon={settings.night_mode ? faCheck : faTimes} /> : <div className="switch">
                            <label>
                                <input id="night_mode" type="checkbox" checked={settings.night_mode} onChange={handleCheckboxChange} />
                                <span className="lever"></span>
                            </label>
                        </div>}
                    </span>
                </li>

                <li className="collection-item">
                    <span className="tooltipped" data-tooltip="Evite la mise en veille de l'appareil en cas d'inactivité">
                        <FontAwesomeIcon icon={faLightbulb} className="fa-fw" style={{marginRight: '10px'}} /> 
                        <span>{t('stay-awake')}</span>
                    </span>
                    <span className="right">
                        {role?.settings.no_sleep.locked ? <FontAwesomeIcon icon={settings.no_sleep ? faCheck : faTimes} /> : <div className="switch">
                            <label>
                                <input id="no_sleep" type="checkbox" checked={settings.no_sleep} onChange={handleCheckboxChange} />
                                <span className="lever"></span>
                            </label>
                        </div>}
                    </span>
                </li>

                <li className="collection-item" style={{lineHeight: '2.5rem'}}>
                    <FontAwesomeIcon icon={faTextHeight} className="fa-fw" style={{marginRight: '10px'}} /> 
                    <span>{t('font-size')}</span>
                    <span className="right">
                        {role?.settings.font_size.locked ? settings.font_size : <>
                            <button 
                                className={"waves-effect waves-light btn-small btn"} 
                                onClick={() => handleChangeFontSize(false)} 
                                disabled={(settings.font_size || 1) <= 0.5}
                            >
                                <FontAwesomeIcon icon={faMinus} />
                            </button>
                            {" " + settings.font_size + " "}
                            <button 
                                className={"waves-effect waves-light btn-small btn"} 
                                onClick={() => handleChangeFontSize(true)} 
                                disabled={(settings.font_size || 1) >= 2}
                            >
                                <FontAwesomeIcon icon={faPlus} />
                            </button>
                        </>}
                    </span>
                </li>

                {isAuthorized('offline', 'R') ? <li className="collection-item">
                    <span className="tooltipped" data-tooltip="Télécharge automatiquement les dernières données exportées pour le mode hors-ligne">
                        <FontAwesomeIcon icon={faSyncAlt} className="fa-fw" style={{marginRight: '10px'}} /> 
                        <span>{t('automatic-update')}</span>
                    </span>
                    <span className="right">
                        {role?.settings.automatic_data_update.locked ? <FontAwesomeIcon icon={settings.automatic_data_update ? faCheck : faTimes} /> : <div className="switch">
                            <label>
                                <input id="automatic_data_update" type="checkbox" checked={settings.automatic_data_update} onChange={handleCheckboxChange} />
                                <span className="lever"></span>
                            </label>
                        </div>}
                    </span>
                </li> : null}

                <li className="collection-item" style={{lineHeight: '2.5rem'}}>
                    <span style={{display: 'inline-block', width: '62%', lineHeight: '1.5rem'}}>
                        <FontAwesomeIcon icon={faRedoAlt} className="fa-fw" style={{marginRight: '10px'}} /> 
                        <span>{t('reload')}</span>
                    </span>
                    <span className="right">
                        <button className="waves-effect waves-light btn-small btn" onClick={() => window.location.href = window.location.href}>{t('reload')}</button>
                    </span>
                </li>
            </ul>
            
            <ul className="collection user-settings">
                <li className="collection-item">
                    <h5 style={{marginBottom: 0}}>{t('route')}</h5>
                </li>

                <li className="collection-item">
                    <FontAwesomeIcon icon={settings.local_mode ? faDatabase : faCloud} className="fa-fw" style={{marginRight: '10px'}} /> 
                    <span>{t('data-source')}</span>
                    <span className="right">
                        {role?.settings.local_mode.locked ? <span>
                            <FontAwesomeIcon icon={settings.local_mode ? faDatabase : faCloud} /> {settings.local_mode ? t('local') : t('online')}
                        </span> : <div className="switch right-align">
                            <label style={{color: 'inherit'}}>
                                <FontAwesomeIcon icon={faDatabase} /> <span>{t('local')}</span>
                                <input id="local_mode" type="checkbox" checked={!settings.local_mode} onChange={handleCheckboxChange} />
                                <span className="lever"></span>
                                <FontAwesomeIcon icon={faCloud} /> <span>{t('online')}</span>
                            </label>
                        </div>}
                    </span>
                </li>

                <li className="collection-item">
                    <span className="tooltipped" data-tooltip="Garde le bandeau contenant les informations du voyage en haut de la page">
                        <FontAwesomeIcon icon={faInfoCircle} className="fa-fw" style={{marginRight: '10px'}} /> 
                        <span>{t('fixed-header')}</span>
                    </span>
                    <span className="right">
                        {role?.settings.fixed_routeboard_header.locked ? <FontAwesomeIcon icon={settings.fixed_routeboard_header ? faCheck : faTimes} /> : <div className="switch">
                            <label>
                                <input id="fixed_routeboard_header" type="checkbox" checked={settings.fixed_routeboard_header} onChange={handleCheckboxChange} />
                                <span className="lever"></span>
                            </label>
                        </div>}
                    </span>
                </li>

                <li className="collection-item" style={{overflow: "hidden"}}>
                    <span className="tooltipped" data-tooltip="Change d'étape en fonction de la géolocalisation et recalcule automatiquement le retard">
                        <FontAwesomeIcon icon={faMapMarkedAlt} className="fa-fw" style={{marginRight: '10px'}} /> 
                        <span>{t('change-of-step-by-geolocation')}</span>
                    </span>
                    <span className="right">
                        {role?.settings.geolocation_step_change.locked ? <FontAwesomeIcon icon={settings.geolocation_step_change ? faCheck : faTimes} /> : <div className="switch">
                            <label>
                                <input id="geolocation_step_change" type="checkbox" checked={settings.geolocation_step_change} onChange={handleCheckboxChange} />
                                <span className="lever"></span>
                            </label>
                        </div>}
                    </span>
                </li>

                <li className="collection-item">
                    <span className="tooltipped" data-tooltip="Démarre le parcours automatiquement à l'heure de départ de la première étape">
                        <FontAwesomeIcon icon={faPlay} className="fa-fw" style={{marginRight: '10px'}} /> 
                        <span>{t('automatic-start')}</span>
                    </span>
                    <span className="right">
                        {role?.settings.automatic_train_start.locked ? <FontAwesomeIcon icon={settings.automatic_train_start ? faCheck : faTimes} /> : <div className="switch">
                            <label>
                                <input id="automatic_train_start" type="checkbox" checked={settings.automatic_train_start} onChange={handleCheckboxChange} />
                                <span className="lever"></span>
                            </label>
                        </div>}
                    </span>
                </li>

                <li className="collection-item" style={{borderBottom: 'none'}}>
                    <span className="tooltipped" data-tooltip="Affiche le parcours du train suivant du dashboard une fois le parcours terminé">
                        <FontAwesomeIcon icon={faExchangeAlt} className="fa-fw" style={{marginRight: '10px'}} /> 
                        <span>{t('automatic-change')}</span>
                    </span>
                    <span className="right">
                        {role?.settings.automatic_train_change.locked ? <FontAwesomeIcon icon={settings.automatic_train_change ? faCheck : faTimes} /> : <div className="switch">
                            <label>
                                <input id="automatic_train_change" type="checkbox" checked={settings.automatic_train_change} onChange={handleCheckboxChange} disabled={settings.automatic_logout} />
                                <span className="lever"></span>
                            </label>
                        </div>}
                    </span>
                </li>

                {settings.automatic_train_change ? <li className="collection-item" style={{lineHeight: '2.5rem', paddingTop: 0}}>
                    <span className="tooltipped" data-tooltip="Délai avant l'affichage automatique du train suivant en secondes">
                        <FontAwesomeIcon icon={faStopwatch} className="fa-fw" style={{marginRight: '10px'}} /> 
                        <span>{t('deadline')}</span>
                    </span>
                    <span className="right">
                        {role?.settings.automatic_train_change_delay.locked ? (settings.automatic_train_change_delay || 0)+ "s " : <>
                            <button 
                                className={"waves-effect waves-light btn-small btn"} 
                                onClick={() => handleChangeDelay('automatic_train_change_delay', false)} 
                                disabled={!settings.automatic_train_change || (settings.automatic_train_change_delay || 15) <= 0}
                            >
                                <FontAwesomeIcon icon={faMinus} />
                            </button>
                            {" " + (settings.automatic_train_change_delay || 0)+ "s "}
                            <button 
                                className={"waves-effect waves-light btn-small btn"} 
                                onClick={() => handleChangeDelay('automatic_train_change_delay', true)} 
                                disabled={!settings.automatic_train_change || (settings.automatic_train_change_delay || 15) >= 120}
                            >
                                <FontAwesomeIcon icon={faPlus} />
                            </button>
                        </>}
                    </span>
                </li> : null}

                <li className="collection-item" style={{borderBottom: 'none'}}>
                    <span className="tooltipped" data-tooltip="Déconnecte l'utilisateur une fois le parcours terminé">
                        <FontAwesomeIcon icon={faPowerOff} className="fa-fw" style={{marginRight: '10px'}} /> 
                        <span>{t('automatic-logout')}</span>
                    </span>
                    <span className="right">
                        {role?.settings.automatic_logout.locked ? <FontAwesomeIcon icon={settings.automatic_logout ? faCheck : faTimes} /> : <div className="switch">
                            <label>
                                <input id="automatic_logout" type="checkbox" checked={settings.automatic_logout} onChange={handleCheckboxChange} disabled={settings.automatic_train_change} />
                                <span className="lever"></span>
                            </label>
                        </div>}
                    </span>
                </li>

                {settings.automatic_logout ? <li className="collection-item" style={{lineHeight: '2.5rem', paddingTop: 0}}>
                    <span className="tooltipped" data-tooltip="Délai avant la déconnexion automatique de l'utilisateur en secondes">
                        <FontAwesomeIcon icon={faStopwatch} className="fa-fw" style={{marginRight: '10px'}} /> 
                        <span>{t('deadline')}</span>
                    </span>
                    <span className="right">
                        {role?.settings.automatic_logout_delay.locked ? (settings.automatic_logout_delay || 0)+ "s " : <>
                            <button 
                                className={"waves-effect waves-light btn-small btn"} 
                                onClick={() => handleChangeDelay('automatic_logout_delay', false)} 
                                disabled={(settings.automatic_logout_delay || 15) <= 0 || !settings.automatic_logout || role?.settings.automatic_logout_delay.locked}
                            >
                                <FontAwesomeIcon icon={faMinus} />
                            </button>
                            {" " + (settings.automatic_logout_delay || 0)+ "s "}
                            <button 
                                className={"waves-effect waves-light btn-small btn"} 
                                onClick={() => handleChangeDelay('automatic_logout_delay', true)}
                                disabled={(settings.automatic_logout_delay || 15) >= 120 || !settings.automatic_logout || role?.settings.automatic_logout_delay.locked}
                            >
                                <FontAwesomeIcon icon={faPlus} />
                            </button>
                        </>}
                    </span>
                </li> : null}

                <li className="collection-item">
                    <FontAwesomeIcon icon={faClock} className="fa-fw" style={{marginRight: '10px'}} /> 
                    <span>{t('show-seconds')}</span>
                    <span className="right">
                        {role?.settings.seconds.locked ? <FontAwesomeIcon icon={settings.seconds ? faCheck : faTimes} /> : <div className="switch">
                            <label>
                                <input id="seconds" type="checkbox" checked={settings.seconds} onChange={handleCheckboxChange} />
                                <span className="lever"></span>
                            </label>
                        </div>}
                    </span>
                </li>

                <li className="collection-item">
                    <FontAwesomeIcon icon={faMap} className="fa-fw" style={{marginRight: '10px'}} /> 
                    <span>{t('show-map')}</span>
                    <span className="right">
                        {role?.settings.route_map.locked ? <FontAwesomeIcon icon={settings.route_map ? faCheck : faTimes} /> : <div className="switch">
                            <label>
                                <input id="route_map" type="checkbox" checked={settings.route_map} onChange={handleCheckboxChange} />
                                <span className="lever"></span>
                            </label>
                        </div>}
                    </span>
                </li>

                <li className="collection-item">
                    <FontAwesomeIcon icon={faCircleDot} className="fa-fw" style={{marginRight: '10px'}} /> 
                    <span>{t('show-all-brake-categories')}</span>
                    <span className="right">
                        {role?.settings.all_brake_categories.locked ? <FontAwesomeIcon icon={settings.all_brake_categories ? faCheck : faTimes} /> : <div className="switch">
                            <label>
                                <input id="all_brake_categories" type="checkbox" checked={settings.all_brake_categories} onChange={handleCheckboxChange} />
                                <span className="lever"></span>
                            </label>
                        </div>}
                    </span>
                </li>

                {/*isAuthorized('trip', 'C') ? <li className="collection-item">
                    <span className="tooltipped" style={{color: '#999'}} data-tooltip="Enregistre les parcours effectués">
                        <FontAwesomeIcon icon={faHistory} className="fa-fw" style={{marginRight: '10px'}} /> 
                        <span>{t('trips-registration')}</span>
                    </span>
                    <span className="right">
                        <div className="switch">
                            <label>
                                <input id="trip_save" type="checkbox" checked={settings.trip_save} onChange={handleCheckboxChange} checked={false} disabled={true} />
                                <span className="lever"></span>
                            </label>
                        </div>
                    </span>
                </li> : null*/}
            </ul>

            <ul className="collection user-settings">
                <li className="collection-item">
                    <h5 style={{marginBottom: 0}}>{t('informations')}</h5>
                </li>

                <li className="collection-item">
                    <span className="tooltipped" data-tooltip="Affiche le prochain arrêt">
                        <FontAwesomeIcon icon={faLocationDot} className="fa-fw" style={{marginRight: '10px'}} /> 
                        <span>{t('next-stop')}</span>
                    </span>
                    <span className="right">
                        {role?.settings.next_stop?.locked ? <FontAwesomeIcon icon={settings.next_stop ? faCheck : faTimes} /> : <div className="switch">
                            <label>
                                <input id="next_stop" type="checkbox" checked={settings.next_stop} onChange={handleCheckboxChange} />
                                <span className="lever"></span>
                            </label>
                        </div>}
                    </span>
                </li>

                <li className="collection-item">
                    <span className="tooltipped" data-tooltip="Affiche la prochaine étape">
                        <FontAwesomeIcon icon={faLocationDot} className="fa-fw" style={{marginRight: '10px'}} /> 
                        <span>{t('next-step')}</span>
                    </span>
                    <span className="right">
                        {role?.settings.next_step?.locked ? <FontAwesomeIcon icon={settings.next_step ? faCheck : faTimes} /> : <div className="switch">
                            <label>
                                <input id="next_step" type="checkbox" checked={settings.next_step} onChange={handleCheckboxChange} />
                                <span className="lever"></span>
                            </label>
                        </div>}
                    </span>
                </li>

                <li className="collection-item">
                    <span className="tooltipped" data-tooltip="Affiche le temps de retard">
                        <FontAwesomeIcon icon={faClock} className="fa-fw" style={{marginRight: '10px'}} /> 
                        <span>{t('late')}</span>
                    </span>
                    <span className="right">
                        {role?.settings.late?.locked ? <FontAwesomeIcon icon={settings.late ? faCheck : faTimes} /> : <div className="switch">
                            <label>
                                <input id="late" type="checkbox" checked={settings.late} onChange={handleCheckboxChange} />
                                <span className="lever"></span>
                            </label>
                        </div>}
                    </span>
                </li>

                <li className="collection-item">
                    <span className="tooltipped" data-tooltip="Affiche le temps d'avance">
                        <FontAwesomeIcon icon={faClock} className="fa-fw" style={{marginRight: '10px'}} /> 
                        <span>{t('advance')}</span>
                    </span>
                    <span className="right">
                        {role?.settings.advance?.locked ? <FontAwesomeIcon icon={settings.advance ? faCheck : faTimes} /> : <div className="switch">
                            <label>
                                <input id="advance" type="checkbox" checked={settings.advance} onChange={handleCheckboxChange} />
                                <span className="lever"></span>
                            </label>
                        </div>}
                    </span>
                </li>

                <li className="collection-item">
                    <span className="tooltipped" data-tooltip="Affiche le décompte avant l'arrivée à la prochaine étape">
                        <FontAwesomeIcon icon={faStopwatch} className="fa-fw" style={{marginRight: '10px'}} /> 
                        <span>{t('countdown')}</span>
                    </span>
                    <span className="right">
                        {role?.settings.arrival_countdown?.locked ? <FontAwesomeIcon icon={settings.arrival_countdown ? faCheck : faTimes} /> : <div className="switch">
                            <label>
                                <input id="arrival_countdown" type="checkbox" checked={settings.arrival_countdown} onChange={handleCheckboxChange} />
                                <span className="lever"></span>
                            </label>
                        </div>}
                    </span>
                </li>

                <li className="collection-item">
                    <span className="tooltipped" data-tooltip="Affiche la vitesse de marche">
                        <FontAwesomeIcon icon={faGauge} className="fa-fw" style={{marginRight: '10px'}} /> 
                        <span>{t('speed')}</span>
                    </span>
                    <span className="right">
                        {role?.settings.speed?.locked ? <FontAwesomeIcon icon={settings.speed ? faCheck : faTimes} /> : <div className="switch">
                            <label>
                                <input id="speed" type="checkbox" checked={settings.speed} onChange={handleCheckboxChange} />
                                <span className="lever"></span>
                            </label>
                        </div>}
                    </span>
                </li>
            </ul>
        </>
    );
};

export default AccountSettings;